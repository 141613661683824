import axios from 'axios';

const initApp = () => import(/* webpackChunkName: "vm" */ './vm.js');

if (process.env.NODE_ENV === 'development') {
  try {
    let date = new Date();
    let Y = date.getFullYear();
    let m = date.getMonth() + 1 + '';
    m = m.length === 1 ? '0' + m : m;
    let d = date.getDate() + '';
    d = d.length === 1 ? '0' + d : d;
    let timestamp = `${Y}-${m}-${d}`;
    window.App = require(`../../../../storage/app/public/app-${timestamp}.json`);
    initApp();
  } catch (err) {
    axios
      .get(process.env.VUE_APP_JSON_URL)
      .then(({ data }) => {
        window.App = data;
        initApp();
      })
      .catch(alert);
  }
} else {
  initApp();
}
